import React from "react";
import { Row, Col } from "react-bootstrap";

import Dots from "../molecules/dots";

import { pushClickEventToDataLayer } from "../atoms/gtmHelper";

export default function Hero(props) {
  return (
    <>
    <Row className="hero">
      <Col md={6}>
        <div className="headline">
          <span>{props.headingPreHeadline}</span>
          <h1 className="mt-3">{props.headingHeadline}</h1>
        </div>
        {props.showDots ? <Dots /> : <div className="hero_gap"/> }
        <div className="hero_image d-sm-block d-md-none">
          {props.introImg}
        </div>
      </Col>
      <Col md={6}>
        <div  className="hero_image d-none d-md-block">
          {props.introImg}
        </div>
      </Col>
    </Row>
    <Row className="hero">
    <Col md={11} xl={6}>
      <div className="intro">
          <h2>{props.introHeadline}</h2>
          <p style={{whiteSpace: 'pre-wrap'}}>{props.introText}</p>
          {props.inviteMail ?  <p style={{whiteSpace: 'pre-wrap'}}>Du willst mitmachen? Melde dich <a onClick={(e) => pushClickEventToDataLayer(e)} id="hi-jobs" className="underline" href="mailto:jobs@missionme.de">
        hier
      </a> mit deiner Initiativbewerbung!</p> : null }
        </div>
         </Col>
         </Row>
    </>
  )
}


