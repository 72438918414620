import React from "react"
import { Link } from "gatsby"

export default function Dots() {
  function changeColor(e,classColor) {
    e.preventDefault();
    const content = document.getElementById('content')
    const footer = document.getElementById('footer')
    const currentColor = document.getElementsByClassName("currentColor")
    while (currentColor.length) {
      currentColor[0].classList.remove("currentColor");
    }
    content.className = content.className.replaceAll(/makeIt\w+/g,'')
    footer.className = footer.className.replaceAll(/makeIt\w+/g,'')
    content.classList.add(classColor)
    footer.classList.add(classColor)
    document.getElementById(classColor).classList.add('currentColor')
  }
  return (
    <div className="dots mt-5 mb-5">
      <Link id="makeItPurple" activeClassName="currentColor" onTouchEnd={(e) => changeColor(e,'makeItPurple')} onClick={(e) => changeColor(e,'makeItPurple')} to="/"/>
      <Link id="makeItOrange" className="navContact" activeClassName="currentColor" onTouchEnd={(e) => changeColor(e,'makeItOrange')} onClick={(e) => changeColor(e,'makeItOrange')} to="/kontakt/"/>
      <Link id="makeItOrange" className="navCompany" activeClassName="currentColor" onTouchEnd={(e) => changeColor(e,'makeItOrange')} onClick={(e) => changeColor(e,'makeItOrange')} to="/company/"/>
      <Link id="makeItGreen" activeClassName="currentColor" onTouchEnd={(e) => changeColor(e,'makeItGreen')} onClick={(e) => changeColor(e,'makeItGreen')} to="/team/"/>
      <Link id="makeItRed" activeClassName="currentColor" onTouchEnd={(e) => changeColor(e,'makeItRed')} onClick={(e) => changeColor(e,'makeItRed')} to="/jobs/"/>
    </div>
  )
}
