import React from "react"

import emailIcon from "../../images/icon_email.svg"
import linkedInicon from "../../images/icon_linkedin.svg"
import xingIcon from "../../images/icon_xing.svg"


export default function Member(props) {
  return (
    <div className="member mt-5 mb-5">
      <img
        className="member-img"
        src={props.memberImg}
        width="100%"
        alt={props.name}
      />
      <h3>{props.name}</h3> 
      <p className="sub-title">{props.memberTitle}</p>
      <div className="social-bar">
        {props.mail &&
          <a href={`mailto:${props.mail}`}><img src={emailIcon} alt="email" /></a>
        }
        {props.linkedin &&
          <a href={props.linkedin} target="_blank" rel="noreferrer"><img src={linkedInicon} alt="linkedin" /></a>
        }
        {props.xing &&
          <a href={props.xing} target="_blank" rel="noreferrer"><img src={xingIcon} alt="xing" /></a>
        }
      </div>   
    </div>
  )
}
