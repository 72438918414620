import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Member from '../molecules/member';
import { pushClickEventToDataLayer } from '../atoms/gtmHelper';
import placeholderImg from '../../images/platzhalter@2x.png';
// peops
import agnesImg from '../../images/agnes@2x.png';
import annaImg from '../../images/anna@2x.png';
import charlotteImg from '../../images/charlotte@2x.png';
import coraImg from '../../images/cora@2x.jpg';
import eckiImg from '../../images/ecki@2x.png';
import hannaImg from '../../images/hanna@2x.png';
import jochenImg from '../../images/jochen@2x.png';
import julieImg from '../../images/julie@2x.png';
import kaiImg from '../../images/kai@2x.png';
import kentImg from '../../images/kent@2x.png';
import marionImg from '../../images/marion@2x.png';
import piaImg from '../../images/pia@2x.png';
import tommyImg from '../../images/tommy@2x.png';
import claudeImg from '../../images/claude@2x.png';
import larissaImg from '../../images/larissa@2x.png';

const team = [
  {
    memberImg: agnesImg,
    name: 'Agnes Breiter',
    memberTitle: 'App & Web Developerin',
    mail: 'abr@missionme.de',
    xing: 'https://www.xing.com/profile/Agnes_Breiter/cv',
  },
  {
    memberImg: annaImg,
    name: 'Anna Wicher',
    memberTitle: 'Managing Director',
    mail: 'awi@missionme.de',
    linkedin: 'https://www.linkedin.com/in/anna-wicher-4b6b0a129/',
    xing: 'https://www.xing.com/profile/Anna_Wicher/cv',
  },
  {
    memberImg: charlotteImg,
    name: 'Charlotte David',
    memberTitle: 'Head of Product',
    mail: 'cda@missionme.de',
    linkedin: 'https://www.linkedin.com/in/charlotte-david-66b575168/',
    xing: 'https://www.xing.com/profile/Charlotte_David/cv',
  },
  {
    memberImg: claudeImg,
    name: 'Claude',
    memberTitle: 'KI Praktikant:in',
    mail: 'info@missionme.de',
  },
  {
    memberImg: coraImg,
    name: 'Cora Steineck',
    memberTitle: 'Content Lead',
    mail: 'cst@missionme.de',
    linkedin: 'https://www.linkedin.com/in/cora-maria-h%C3%A4nel-7b3aa8106/',
  },
  {
    memberImg: eckiImg,
    name: 'Eckhard Rotte',
    memberTitle: 'Senior Developer',
    mail: 'ero@missionme.de',
    linkedin: 'https://www.linkedin.com/in/eckhard-rotte-b566796',
    xing: 'https://xing.to/erotte',
  },
  {
    memberImg: hannaImg,
    name: 'Hanna Wolff',
    memberTitle: 'Product Designerin',
    mail: 'hwo@missionme.de',
  },
  {
    memberImg: jochenImg,
    name: 'Jochen Kramer',
    memberTitle: 'Head of Technology',
    mail: 'jkr@missionme.de',
    linkedin: 'https://www.linkedin.com/in/jochen-kramer-1301051b5',
    xing: 'https://www.xing.com/profile/Jochen_Kramer2',
  },
  {
    memberImg: kaiImg,
    name: 'Kai Hübner',
    memberTitle: 'Data Analyst',
    mail: 'khu@missionme.de',
    xing: 'https://www.xing.com/profile/Kai_Huebner20',
  },
  {
    memberImg: kentImg,
    name: 'Kent Erbacher',
    memberTitle: 'Senior Partnerships Manager',
    mail: 'ker@missionme.de',
    linkedin: 'https://www.linkedin.com/in/kent-erbacher/',
    xing: 'https://www.xing.com/profile/Kent_Erbacher/cv',
  },
  {
    memberImg: julieImg,
    name: 'Julie Falk',
    memberTitle: 'Customer Happiness & Content',
    mail: 'jfa@missionme.de',
  },
  {
    memberImg: piaImg,
    name: 'Pia Abel',
    memberTitle: 'Community Lead',
    mail: 'pab@missionme.de',
    linkedin: 'https://www.linkedin.com/in/pia-abel-ab8756140/',
    xing: 'https://www.xing.com/profile/Pia_Abel3/cv',
  },
  {
    memberImg: tommyImg,
    name: 'Tommy Bley',
    memberTitle: 'Lead Developer App & Web',
    mail: 'tbl@missionme.de',
    xing: 'https://www.xing.com/profile/Tommy_Bley/cv',
  },
  {
    memberImg: larissaImg,
    name: 'Larissa König',
    memberTitle: 'Customer Happiness & Product',
    mail: 'lko@missionme.de',
  },
  {
    memberImg: marionImg,
    name: 'Marion Winter-Bertóthy',
    memberTitle: 'Compliance- und Prozess-Support',
    mail: 'mwi@missionme.de',
  },
];

export default function Members(props) {
  return (
    <Row>
      {team
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((teamMember, i) => (
          <Col className="member-col" sm={6} md={3} key={`member${i}`}>
            <Member {...teamMember} />
          </Col>
        ))}
      <Col className="member-col" sm={6} md={3}>
        <Link onClick={(e) => pushClickEventToDataLayer(e)} id="members-job" to="/jobs/">
          <Member memberImg={placeholderImg} name="Du?" memberTitle="Offene Stelle" mail="jobs@missionme.de" />
        </Link>
      </Col>
    </Row>
  );
}
