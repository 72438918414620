import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Layout from "../components/templates/layout";
import SEO from "../components/templates/seo";

import Hero from "../components/organisms/hero";

import Members from "../components/organisms/members";

import { pushClickEventToDataLayer } from "../components/atoms/gtmHelper";

const TeamPage = () => (
  <Layout pageInfo={{ pageName: "team" }}>
    <SEO title="MissionMe - Team" />
    <Container fluid="md">
      <Hero
        showDots
        headingPreHeadline="Team"
        headingHeadline="Lerne das Team hinter MissionMe kennen"
        introHeadline="Wofür wir stehen"
        introText="Smarte, schöne und sinnvolle digitale Produkte – dafür schlägt unser Herz und dafür arbeiten wir jeden Tag zusammen. Wir arbeiten designverliebt, kundenzentriert, iterativ, datengeleitet und zahlenbasiert um das bestmögliche Ergebnis zu erzielen. Wir leben flache Hierarchien und können so schnell auf neue Ideen und dein individuelles Feedback reagieren. Wir sind ein kleines, bewegliches Team; wir machen das einfach und haben Spaß dabei."
        introImg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.183 396.796">
        <g transform="translate(-0.181)">
            <path
                d="M295.973,354.747,68.765,282.79,140.723,55.582l227.208,71.957ZM132.906,249.5l129.782,41.1,41.1-129.783-129.783-41.1Z"
                fill="#662483" className="corner"/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(339.852 347.153)" fill="#f39200" className="point"/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(361.119 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(382.386 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(403.653 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(424.92 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(446.187 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(467.454 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(488.721 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(509.988 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(531.255 347.153)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(339.852 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(361.119 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(382.386 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(403.653 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(424.92 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(446.187 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(467.454 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(488.721 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(509.988 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(531.255 368.42)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(339.852 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(361.119 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(382.386 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(403.653 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(424.92 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(446.187 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(467.454 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(488.721 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(509.988 389.687)" fill="#f39200"  className="point"/>/>
            <circle cx="3.555" cy="3.555" r="3.555" transform="translate(531.255 389.687)" fill="#f39200"  className="point"/>/>
            <path
                d="M351.506,142.207a14.882,14.882,0,0,0-20.147,4.816l-2.13,3.39a17.792,17.792,0,0,1-23.993,5.8l-2.967-1.761a14.785,14.785,0,0,0-20.053,4.958l-1.9,3.1a17.489,17.489,0,0,1-23.829,5.825l-2.8-1.681a14.522,14.522,0,0,0-19.856,4.963l-2.057,3.425a17.386,17.386,0,0,1-23.733,5.995l-3.593-2.131a14.418,14.418,0,0,0-19.754,5.1l-1.84,3.134a16.9,16.9,0,0,1-10.518,7.9l-.044.011a16.9,16.9,0,0,1-12.964-1.968l-3.261-1.978a14.785,14.785,0,0,0-20.1,4.75l-2.13,3.39a17.586,17.586,0,0,1-23.922,5.657l-2.948-1.791a14.516,14.516,0,0,0-19.881,4.859l-2.167,3.573a17.4,17.4,0,0,1-23.763,5.874l-3.113-1.868a14.523,14.523,0,0,0-19.856,4.962l-2.244,3.74a17,17,0,0,1-10.522,7.789l-.09.023a16.985,16.985,0,0,1-13.013-2.038l-3.409-2.092a14.371,14.371,0,0,0-8.727-2.07v13.787c.367-.106.731-.218,1.108-.3a17.144,17.144,0,0,1,13.05,2.062l3.407,2.091a14.207,14.207,0,0,0,10.887,1.7l.074-.019a14.2,14.2,0,0,0,8.8-6.514l2.244-3.739a17.321,17.321,0,0,1,23.7-5.922l3.113,1.868a14.587,14.587,0,0,0,19.929-4.927l2.169-3.572a17.144,17.144,0,0,1,10.577-7.775l.088-.022a17.155,17.155,0,0,1,13.061,2l2.949,1.791a14.786,14.786,0,0,0,20.1-4.75l2.129-3.393a17.585,17.585,0,0,1,23.921-5.654l3.263,1.98a14.119,14.119,0,0,0,10.831,1.642l.036-.009a14.126,14.126,0,0,0,8.792-6.6l1.836-3.136a17.068,17.068,0,0,1,10.535-7.94l.134-.033a17.06,17.06,0,0,1,12.929,1.881l3.6,2.133a14.583,14.583,0,0,0,19.9-5.027l2.056-3.428a17.321,17.321,0,0,1,23.7-5.922l2.8,1.681a14.685,14.685,0,0,0,20-4.889l1.9-3.1a17.6,17.6,0,0,1,23.866-5.9l2.967,1.758a14.985,14.985,0,0,0,20.2-4.881l2.129-3.393a17.691,17.691,0,0,1,23.958-5.728l2.432,1.459V146.124a17.157,17.157,0,0,1-4.41-1.862Z"
                fill="none" />
            <path
                d="M355.471,158.071a14.879,14.879,0,0,0-20.147,4.816l-2.13,3.392a17.8,17.8,0,0,1-23.993,5.8l-2.967-1.758a14.787,14.787,0,0,0-20.054,4.955l-1.9,3.1a17.494,17.494,0,0,1-23.829,5.824l-2.8-1.682a14.522,14.522,0,0,0-19.855,4.962l-2.056,3.428a17.4,17.4,0,0,1-23.732,6l-3.6-2.134a14.082,14.082,0,0,0-10.933-1.545,14.283,14.283,0,0,0-8.82,6.642L186.818,203a16.9,16.9,0,0,1-10.52,7.9l-.043.011a16.9,16.9,0,0,1-12.964-1.965l-3.262-1.98a14.782,14.782,0,0,0-20.1,4.749l-2.13,3.392a17.591,17.591,0,0,1-23.922,5.655l-2.949-1.791a14.352,14.352,0,0,0-10.941-1.67,14.528,14.528,0,0,0-8.937,6.531l-2.171,3.572a17.386,17.386,0,0,1-23.762,5.873l-3.113-1.868a14.521,14.521,0,0,0-19.855,4.962l-2.245,3.74a16.979,16.979,0,0,1-10.52,7.789l-.089.022a16.985,16.985,0,0,1-13.016-2.037l-3.406-2.09a14.379,14.379,0,0,0-10.936-1.725,17.043,17.043,0,0,0-1.758.594v14.655a17.364,17.364,0,0,1,4.894-2.064c.06-.015.118-.029.178-.042A17.146,17.146,0,0,1,18.3,257.278l3.408,2.093a14.21,14.21,0,0,0,10.885,1.7,14.717,14.717,0,0,0,8.874-6.533l2.244-3.739a17.312,17.312,0,0,1,23.7-5.922l3.114,1.87a14.583,14.583,0,0,0,19.927-4.929l2.171-3.572a17.153,17.153,0,0,1,10.577-7.772l.088-.022a17.148,17.148,0,0,1,13.061,1.995l2.948,1.791a14.782,14.782,0,0,0,20.1-4.75l2.13-3.39a17.582,17.582,0,0,1,23.921-5.657l3.262,1.98a13.983,13.983,0,0,0,10.872,1.634,14.124,14.124,0,0,0,8.786-6.6l1.838-3.137a17.068,17.068,0,0,1,10.535-7.939l.134-.033a17.06,17.06,0,0,1,12.929,1.881l3.6,2.133a14.585,14.585,0,0,0,19.9-5.027l2.056-3.428a17.313,17.313,0,0,1,23.7-5.922l2.8,1.681a14.686,14.686,0,0,0,20-4.889l1.9-3.1a17.6,17.6,0,0,1,23.866-5.9l2.968,1.76a14.975,14.975,0,0,0,20.2-4.883l2.13-3.39a17.7,17.7,0,0,1,22.424-6.549V160.366c-.147-.082-.3-.15-.444-.238Z"
                fill="none" />
            <path
                d="M8.908,227.932l3.409,2.092a16.985,16.985,0,0,0,13.013,2.038l.09-.023a17,17,0,0,0,10.522-7.789l2.244-3.74a14.523,14.523,0,0,1,19.856-4.962l3.113,1.868a17.4,17.4,0,0,0,23.763-5.874l2.167-3.573a14.516,14.516,0,0,1,19.881-4.859l2.948,1.791a17.586,17.586,0,0,0,23.922-5.657l2.13-3.39a14.785,14.785,0,0,1,20.1-4.75l3.261,1.978a16.9,16.9,0,0,0,12.964,1.968l.044-.011a16.9,16.9,0,0,0,10.518-7.9l1.84-3.134a14.418,14.418,0,0,1,19.754-5.1l3.593,2.131a17.386,17.386,0,0,0,23.733-5.995l2.057-3.425a14.522,14.522,0,0,1,19.856-4.963l2.8,1.681a17.489,17.489,0,0,0,23.829-5.825l1.9-3.1a14.785,14.785,0,0,1,20.053-4.958l2.967,1.761a17.792,17.792,0,0,0,23.993-5.8l2.13-3.39a14.882,14.882,0,0,1,20.147-4.816l3.427,2.055a17.157,17.157,0,0,0,4.41,1.862V143.2a14.343,14.343,0,0,1-2.969-1.337l-3.427-2.055a17.688,17.688,0,0,0-23.958,5.728l-2.13,3.39a14.98,14.98,0,0,1-20.2,4.884l-2.967-1.761a17.6,17.6,0,0,0-23.866,5.9l-1.9,3.1a14.679,14.679,0,0,1-20,4.892l-2.8-1.681a17.32,17.32,0,0,0-23.7,5.922l-2.057,3.425a14.586,14.586,0,0,1-19.9,5.028l-3.6-2.131a17.057,17.057,0,0,0-12.928-1.881l-.134.033a17.06,17.06,0,0,0-10.534,7.939l-1.84,3.135a14.122,14.122,0,0,1-8.79,6.6l-.036.009a14.125,14.125,0,0,1-10.833-1.644l-3.26-1.978a17.582,17.582,0,0,0-23.922,5.654l-2.129,3.39a14.779,14.779,0,0,1-20.1,4.752l-2.949-1.79a17.152,17.152,0,0,0-13.06-2,17.6,17.6,0,0,0-10.667,7.795l-2.168,3.574A14.586,14.586,0,0,1,62.6,215.016l-3.113-1.868a17.32,17.32,0,0,0-23.7,5.922l-2.245,3.74a14.213,14.213,0,0,1-8.8,6.514l-.075.018a14.213,14.213,0,0,1-10.883-1.7l-3.408-2.093A17.149,17.149,0,0,0,.181,223.056v2.806A14.371,14.371,0,0,1,8.908,227.932Z"
                fill="#ea504c" className="wave"/>
            <path
                d="M332.953,161.4l-2.129,3.393a14.985,14.985,0,0,1-20.2,4.881l-2.967-1.758a17.6,17.6,0,0,0-23.866,5.9l-1.9,3.1a14.685,14.685,0,0,1-20,4.889l-2.8-1.681a17.321,17.321,0,0,0-23.7,5.922l-2.056,3.428a14.583,14.583,0,0,1-19.9,5.027l-3.6-2.133a17.06,17.06,0,0,0-12.929-1.881l-.134.033a17.068,17.068,0,0,0-10.535,7.94L184.4,201.59a14.126,14.126,0,0,1-8.792,6.6l-.036.009a14.119,14.119,0,0,1-10.831-1.642l-3.263-1.98a17.585,17.585,0,0,0-23.921,5.654l-2.129,3.393a14.786,14.786,0,0,1-20.1,4.75l-2.949-1.791a17.155,17.155,0,0,0-13.061-2l-.088.022a17.144,17.144,0,0,0-10.577,7.775l-2.169,3.572A14.587,14.587,0,0,1,66.56,230.88l-3.113-1.868a17.321,17.321,0,0,0-23.7,5.922l-2.244,3.739a14.2,14.2,0,0,1-8.8,6.514l-.074.019a14.207,14.207,0,0,1-10.887-1.7l-3.407-2.091a17.144,17.144,0,0,0-13.05-2.062c-.377.079-.741.191-1.108.3v3.018a17.043,17.043,0,0,1,1.758-.594A14.379,14.379,0,0,1,12.875,243.8l3.406,2.09A16.985,16.985,0,0,0,29.3,247.925l.089-.022a16.979,16.979,0,0,0,10.52-7.789l2.245-3.74a14.521,14.521,0,0,1,19.855-4.962l3.113,1.868a17.386,17.386,0,0,0,23.762-5.873l2.171-3.572a14.528,14.528,0,0,1,8.937-6.531,14.352,14.352,0,0,1,10.941,1.67l2.949,1.791A17.591,17.591,0,0,0,137.8,215.11l2.13-3.392a14.782,14.782,0,0,1,20.1-4.749l3.262,1.98a16.9,16.9,0,0,0,12.964,1.965l.043-.011a16.9,16.9,0,0,0,10.52-7.9l1.838-3.137a14.283,14.283,0,0,1,8.82-6.642,14.082,14.082,0,0,1,10.933,1.545l3.6,2.134a17.4,17.4,0,0,0,23.732-6l2.056-3.428a14.522,14.522,0,0,1,19.855-4.962l2.8,1.682a17.494,17.494,0,0,0,23.829-5.824l1.9-3.1a14.787,14.787,0,0,1,20.054-4.955l2.967,1.758a17.8,17.8,0,0,0,23.993-5.8l2.13-3.392a14.879,14.879,0,0,1,20.147-4.816l3.428,2.057c.145.088.3.156.444.238V157.13l-2.432-1.459A17.691,17.691,0,0,0,332.953,161.4Z"
                fill="#ea504c" className="wave" />
            <path
                d="M336.919,177.266l-2.13,3.39a14.975,14.975,0,0,1-20.2,4.883l-2.968-1.76a17.6,17.6,0,0,0-23.866,5.9l-1.9,3.1a14.686,14.686,0,0,1-20,4.889l-2.8-1.681a17.313,17.313,0,0,0-23.7,5.922l-2.056,3.428a14.585,14.585,0,0,1-19.9,5.027l-3.6-2.133a17.06,17.06,0,0,0-12.929-1.881l-.134.033a17.068,17.068,0,0,0-10.535,7.939l-1.838,3.137a14.124,14.124,0,0,1-8.786,6.6,13.983,13.983,0,0,1-10.872-1.634l-3.262-1.98a17.582,17.582,0,0,0-23.921,5.657l-2.13,3.39a14.782,14.782,0,0,1-20.1,4.75l-2.948-1.791a17.148,17.148,0,0,0-13.061-1.995l-.088.022a17.153,17.153,0,0,0-10.577,7.772l-2.171,3.572a14.583,14.583,0,0,1-19.927,4.929l-3.114-1.87a17.312,17.312,0,0,0-23.7,5.922l-2.244,3.739A14.717,14.717,0,0,1,32.6,261.07a14.21,14.21,0,0,1-10.885-1.7L18.3,257.278a17.146,17.146,0,0,0-13.051-2.062c-.06.013-.118.027-.178.042a17.364,17.364,0,0,0-4.894,2.064v3.472A15.4,15.4,0,0,1,5.9,257.936a14.379,14.379,0,0,1,10.934,1.726l3.409,2.093a16.976,16.976,0,0,0,13.016,2.034,17.423,17.423,0,0,0,10.608-7.811l2.244-3.74a14.515,14.515,0,0,1,19.856-4.962l3.113,1.871a17.391,17.391,0,0,0,23.762-5.876l2.169-3.572a14.519,14.519,0,0,1,19.88-4.861l2.948,1.791a17.593,17.593,0,0,0,23.923-5.655l2.13-3.39a14.783,14.783,0,0,1,20.1-4.751l3.262,1.98a16.908,16.908,0,0,0,12.967,1.967l.044-.011a16.91,16.91,0,0,0,10.517-7.9l1.838-3.137a14.28,14.28,0,0,1,8.819-6.642l.111-.028a14.279,14.279,0,0,1,10.823,1.573l3.6,2.134a17.392,17.392,0,0,0,23.732-6l2.056-3.428a14.515,14.515,0,0,1,19.856-4.962l2.8,1.681a17.5,17.5,0,0,0,23.83-5.823l1.9-3.1a14.778,14.778,0,0,1,20.051-4.957l2.968,1.76a17.792,17.792,0,0,0,23.992-5.8l2.131-3.39a14.88,14.88,0,0,1,20.054-4.867v-3.169A17.7,17.7,0,0,0,336.919,177.266Z"
                fill="#ea504c" className="wave" />
            <circle cx="108.574" cy="108.574" r="108.574" transform="translate(238.19 0)" fill="#f39200" className="bigcircle"/>
            <circle cx="61.436" cy="61.436" r="61.436" transform="translate(374.149 141.974)" fill="#ea504c"
                className="smallcircle" />
        </g>
    </svg>}
      />
      <Members/>
      <Row>
        <Col md={12} className="d-flex justify-content-center">
          <Link onClick={(e) => pushClickEventToDataLayer(e)} id="team-jobs" className="mt-4 btn btn-primary" to="/jobs/">Join our Team!</Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TeamPage
